import ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import slugify from 'slugify';

const HomepageCategories = ({ categories }) => {
  const [isBrowser, setIsBrowser] = useState(false);
  const [isTopRowOpen, setIsTopRowOpen] = useState(false);
  const [isBottomRowOpen, setIsBottomRowOpen] = useState(false);
  const [isOnAuto, setIsOnAuto] = useState(false);
  const [isVertical, setIsVertical] = useState(false);

  useEffect(() => {
    setIsBrowser(true);
  }, []);

  useEffect(() => {
    setIsOnAuto(isBrowser && window.location.href.includes('auto.co.zw'));
  }, [isBrowser]);

  useEffect(() => {
    setIsVertical(window.location.href.includes('zimbabwe-'));

  }, [isVertical]);

  const topStyles = {
    display: isTopRowOpen ? 'block' : 'none',
  };

  const bottomStyles = {
    display: isBottomRowOpen ? 'block' : 'none',
  };

  return (
    <ul className="HomepageCategories">
      {categories &&
        categories.map((cat, key) => {
          const isTopRow = key <= 4;
          return (
            <li key={cat['id']} className="HomepageCategories__Item">
              <a className="HomepageCategories__Item__Title" href={cat['link']}>
                <div
                  style={{ display: 'flex', alignItems: 'center' }}
                  className="HomepageCategories__Item__Title__Text"
                >
                  {isOnAuto ? (
                    <img
                      style={{
                        width: '1.5rem',
                        height: '1.5rem',
                        marginRight: '0.5rem',
                      }}
                      className="overflow-hidden"
                      src={`${
                        location.origin
                      }/resources/themes/auto_co_zw/img/category/${slugify(
                        cat['title']
                      )
                        .toLowerCase()
                        .replaceAll('-and-', '-')}.jpg`}
                      alt={`${cat['title']} icon`}
                    />
                  ) : isVertical ? (
                    <img
                      style={{

                        marginRight: '0.5rem',
                      }}
                      className="overflow-hidden"
                      src={`${
                        location.origin
                      }/build/themes/zimbabwe/img/category_icons/${
                        cat['id'] + '-24x22px.png'
                      }`}
                      alt={`${cat['title']} icon`}
                    />
                  ) : (
                    <span className={`icon-home icon-home-${cat['id']}`}></span>
                  )}
                  <span
                    style={{
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                    title={`${cat['title']}`}
                  >{`${cat['title']}`}</span>
                </div>
                {cat['total_ads'] && (
                  <div className="HomepageCategories__Item__Title__Count">
                    ({cat['total_ads']})
                  </div>
                )}
              </a>
              <ul className="HomepageCategories__Item__SubCategories">
                {Array.isArray(cat.subCategories) &&
                  cat.subCategories?.map((kitten, subKey) => {
                    const isExtraSubCategory = subKey >= 5;
                    return (
                      <li
                        key={kitten['id']}
                        className="HomepageCategories__Item__SubCategory"
                        style={
                          isExtraSubCategory
                            ? isTopRow
                              ? topStyles
                              : bottomStyles
                            : null
                        }
                      >
                        <a
                          id={`HomepageCategories__Item__SubCategory__${kitten['id']}`}
                          className="HomepageCategories__Item__SubCategory__Title"
                          style={{
                            color: '#0e5b93',
                            width: '95%',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                          }}
                          title={`${kitten['title']}`}
                          href={kitten['link']}
                        >
                          {kitten['title']}
                        </a>
                      </li>
                    );
                  })}
              </ul>
              <button
                className="HomepageCategories__Item__SubCategories__More"
                style={{ color: '#0e5b93' }}
                onClick={() => {
                  isTopRow
                    ? setIsTopRowOpen(!isTopRowOpen)
                    : setIsBottomRowOpen(!isBottomRowOpen);
                }}
              >
                <span>
                  {isTopRowOpen && isTopRow
                    ? 'Less'
                    : isBottomRowOpen && !isTopRow
                    ? 'Less'
                    : 'More'}
                </span>
                <span>
                  <i
                    className="fa fa-angle-down"
                    style={{
                      transform:
                        isTopRowOpen && isTopRow
                          ? 'rotate(180deg)'
                          : isBottomRowOpen && !isTopRow
                          ? 'rotate(180deg)'
                          : 'rotate(0)',
                    }}
                  ></i>
                </span>
              </button>
            </li>
          );
        })}
    </ul>
  );
};

export default HomepageCategories;

/**
 * Mount to DOM
 */
let targetDOMElement = document.getElementById('home-categories');

if (targetDOMElement) {
  let categories = JSON.parse(targetDOMElement.getAttribute('data-categories'));
  categories = Object.keys(categories).map((key) => categories[key]);
  ReactDOM.render(
    <HomepageCategories categories={categories} />,
    targetDOMElement
  );
}
